<template>
  <sm-master-layout background-color="#f5f5f5">
    <div class="sm-cart">
      <div class="sm-cart-info">
        <span>Hier werden alle hinzugefügten Artikel angezeigt</span>
      </div>

      <div class="sm-cart-line-items-container">
        <template v-if="!isEmptyLineItems">
          <h3>Artikel:</h3>

          <ion-list class="sm-cart-line-items" lines="full" mode="ios">
            <ion-item
              v-for="lineItem in lineItems"
              :key="lineItem.id"
            >
              <ion-grid>
                <ion-row>
                  <ion-col size="4" @click="loadProductFromStorage(lineItem.referencedId)" style="cursor:pointer;">
                    <img class="sm-line-item-image" :src="getLineItemImage(lineItem.referencedId)" alt="Article cover image">
                  </ion-col>

                  <ion-col size="8">
                    <h3 @click="loadProductFromStorage(lineItem.referencedId)" style="cursor:pointer;">{{ getLineItemProduct(lineItem.referencedId).name }}</h3>

                    <p @click="loadProductFromStorage(lineItem.referencedId)" style="cursor:pointer;" v-if="lineItem.customProductNo">
                      Produkt-Nr.: {{ getLineItemProduct(lineItem.referencedId).productNo }} ({{ getLineItemProduct(lineItem.referencedId).customProductNo }})
                    </p>
                    <p @click="loadProductFromStorage(lineItem.referencedId)" style="cursor:pointer;" v-else>
                      Produkt-Nr.: {{ getLineItemProduct(lineItem.referencedId).productNo }}
                    </p>
                    <p
                        @click="loadProductFromStorage(lineItem.referencedId)" style="cursor:pointer;"
                        v-if="getDeliveryTime(lineItem.referencedId)"
                        class="sm-line-item-delivery-time"
                        :class="getDeliveryTime(lineItem.referencedId)['color']">
                      <span>.</span>
                      <span>{{ getDeliveryTime(lineItem.referencedId)['label'] }}</span>
                    </p>
<!--                    <p v-if="lineItem.freeArticle" class="sm-product-request">-->
<!--                      Produktwunsch: <span>{{ isReadingMore ? lineItem.freeArticleRequestMessage : truncateString(lineItem.freeArticleRequestMessage, 15) }}</span>-->
<!--                      <span class="sm-read-more-btn" @click="toggleReadMore">{{ !isReadingMore ? 'mehr anzeigen' : 'weniger anzeigen' }}</span>-->
<!--                    </p>-->
                    <div class="sm-line-item-quantity-container">
                      <strong>Anzahl</strong>

                      <div class="sm-line-item-quantity">
                        <ion-icon @click="onRemoveLineItemQuantity(lineItem.id)" :icon="removeIcon" slot="end"></ion-icon>
                        <ion-input readonly :value="lineItem.quantity"></ion-input>
                        <ion-icon @click="onAddLineItemQuantity(lineItem.id)" :icon="addIcon" slot="end"></ion-icon>
                      </div>

                      <div class="sm-line-item-remove">
                        <ion-icon @click="onDeleteLineItem(lineItem.id)" :icon="trashIcon" slot="end"></ion-icon>
                      </div>
                    </div>
                  </ion-col>
                </ion-row>
              </ion-grid>
            </ion-item>
          </ion-list>
        </template>

        <div v-else class="sm-cart-line-items-empty">
          <ion-icon :icon="alertCircle" color="danger" slot="end"></ion-icon>
          <strong>Der warenkorb ist leer</strong>
        </div>
      </div>

      <div class="sm-list-button">
        <template v-if="isEmptyLineItems">
          <div class="sm-modal-btn-checkout">
            <ion-button @click="router.push({name: 'scan-qr'})" expand="block" color="primary">QR-CODE SCANNEN</ion-button>
          </div>
        </template>
        <template v-else>
          <div class="sm-modal-btn-checkout">
            <ion-button @click="router.push({name: 'addresses'})" expand="block" color="primary">zur kasse</ion-button>
          </div>
          <div class="sm-modal-btn-qr-code">
            <ion-button @click="router.push({name: 'scan-qr'})" expand="block" color="secondary">Code scannen</ion-button>
          </div>
        </template>
      </div>
    </div>
  </sm-master-layout>
</template>

<script lang="javascript">
import _ from 'lodash';
import { IonCol, IonGrid, IonRow, IonList, IonItem, IonIcon, IonInput, alertController, IonButton } from '@ionic/vue';
import { defineComponent } from 'vue';
import {mapGetters, mapMutations} from 'vuex';
import CartService from '@/services/cart.service';
import ProductService from '@/services/product.service';
import SmMasterLayout from "@/components/layout/SmMasterLayout";
import ScreenLoadingMixin from '@/mixins/screen-loading.mixin';
import { useRouter } from "vue-router";
import { alertCircle, add, remove, trashOutline } from "ionicons/icons";

export default defineComponent({
  name: 'Home',

  mixins: [ScreenLoadingMixin],

  components: {
    SmMasterLayout,
    IonCol,
    IonGrid,
    IonRow,
    IonList,
    IonItem,
    IonIcon,
    IonInput,
    IonButton
  },

  setup() {
    const router = useRouter();
    return {
      router,
      alertCircle,
      removeIcon: remove,
      addIcon: add,
      trashIcon: trashOutline,
    };
  },

  data () {
    return {
      lineItems: {},
      lineItemProducts: {},
      isReadingMore: false,
    }
  },

  computed: {
    ...mapGetters('auth', ['getCustomer']),

    isEmptyLineItems() {
      return _.isEmpty(this.lineItems);
    }
  },

  async ionViewWillEnter() {
    this.setHeaderTitle('Warenkorb');
    await this.getLineItems();
    await this.getLineItemProducts();
  },

  methods: {
    ...mapMutations('common', ['setHeaderTitle']),
    ...mapMutations('product', ['setScannedProduct']),

    truncateString(str, num) {
      if (!str) {
        return '';
      }

      if (str.length > num) {
        return str.slice(0, num) + "...";
      } else {
        return str;
      }
    },

    // toggleReadMore() {
    //   this.isReadingMore = !this.isReadingMore;
    // },

    getDeliveryTime(productId) {
      const product = this.lineItemProducts[productId];

      if (!product) {
        return null;
      }

      const deliveryTime = product.deliveryTime;

      if (!deliveryTime) {
        return null;
      } else if (deliveryTime.max < 4) {
        return {
          color: 'text-success',
          label: `Lieferbar in ${deliveryTime.min}-${deliveryTime.max} Werktagen`
        }
      } else if (deliveryTime.max < 15) {
        return {
          color: 'text-warning',
          label: `Lieferbar in ${deliveryTime.min}-${deliveryTime.max} Werktagen`
        }
      } else {
        const deliveryMinWeek = Math.round(deliveryTime.min / 7);
        const deliveryMaxWeek = Math.round(deliveryTime.max / 7);

        return {
          color: 'text-danger',
          label: `Lieferbar in ${deliveryMinWeek}-${deliveryMaxWeek} Wochen`
        }
      }
    },

    async getLineItems() {
      this.lineItems = await CartService.getLineItemsFromIdb(this.getCustomer.id);
    },

    getLineItemProduct(id) {
      if (!this.lineItemProducts[id]) {
        return {};
      }

      return this.lineItemProducts[id];
    },

    getLineItemImage(id) {
      if (this.lineItemProducts[id] && this.lineItemProducts[id].media) {
        return `data:image/png;base64, ${this.lineItemProducts[id].media}`;
      }

      return 'img/placeholder.svg';
    },

    async getLineItemProducts() {
      for (const item of this.lineItems) {
        let product = await ProductService.getProductFromIdb(item.referencedId);
        product.customProductNo = item.customProductNo;
        this.lineItemProducts[item.referencedId] = product;
      }
    },

    async onRemoveLineItemQuantity(id) {
      let lineItem = this.lineItems.find(item => {
        return item.id === id;
      })

      if (lineItem && lineItem.quantity > 1) {
        --lineItem.quantity;
        // Save to idb
        await CartService.setLineItem(this.getCustomer.id, lineItem);
      }
    },

    onChangeLineItemQuantity() {

    },

    async onAddLineItemQuantity(id) {
      let lineItem = this.lineItems.find(item => {
        return item.id === id;
      })

      if (lineItem && lineItem.quantity < 100) {
        ++lineItem.quantity;
        // Save to idb
        await CartService.setLineItem(this.getCustomer.id, lineItem);
      }
    },

    async onDeleteLineItem(id) {
      const lineItemIndex = this.lineItems.findIndex(item => (item.id === id))
      const confirm = await this.warnDelete();

      if (confirm && lineItemIndex > -1) {
        this.lineItems.splice(lineItemIndex, 1);
        // delete from idb
        await CartService.removeLineItem(this.getCustomer.id, id);
      }
    },

    /* eslint-disable */
    async warnDelete() {
      return new Promise(async (resolve) => {
        const confirm = await alertController.create({
          header: 'Sind Sie sicher?',
          message: 'Möchten Sie dieses Produkt aus Ihrem Warenkorb entfernen?',
          buttons: [
            {
              text: 'Abbrechen',
              role: 'Abbrechen',
              handler: () => {
                return resolve(false);
              },
            },
            {
              text: 'Ja',
              handler: () => {
                return resolve(true);
              },
            },
          ],
        });

        await confirm.present();
      });
    },
    /* eslint-disable */

    async loadProductFromStorage(id) {
      const loading = await this.presentScreenLoading();

      try {
        let product = this.lineItemProducts[id]

        if (product) {
          product.id = id;
          await this.setScannedProduct(product);
          this.dismissScreenLoading(loading);
          this.$router.push({name: 'scanned-product'});
        } else {
          this.dismissScreenLoading(loading);
        }
      } catch (e) {
        this.dismissScreenLoading(loading);
        console.log(e);
      }
    },
  }
})
</script>

<style scoped>
  .sm-cart {
    margin-bottom: 10rem;
  }

  .sm-cart-info {
    margin: 1.5rem 0;
  }

  .sm-cart-line-items-container h3 {
    font-size: 1.14rem;
  }

  .sm-cart-line-items-empty {
    height: calc(70vh - 60px);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .sm-cart-line-items-empty ion-icon {
    font-size: 1.5rem;
    margin-right: 0.5rem;
  }

  .sm-cart-line-items-empty strong {
    text-transform: uppercase;
    font-size: 1.125rem;
  }

  .sm-modal-btn-qr-code {
    margin-top: 1rem;
  }

  .sm-cart-line-items-container .sm-cart-line-items h3 {
    font-size: 1rem;
    margin: 0;
    font-weight: bold;
  }

  .sm-cart-line-items-container .sm-cart-line-items p {
    font-size: 0.875rem;
  }

  .sm-line-item-quantity-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .sm-line-item-quantity-container .sm-line-item-quantity {
    display: flex;
    align-items: center;
    border: 1px solid #979797;
    width: 55%;
  }

  .sm-line-item-quantity-container strong {
    font-size: 1rem;
  }

  .sm-line-item-quantity-container .sm-line-item-quantity ion-icon {
    font-size: 1.2rem;
    margin: 0 0.5rem;
  }

  .sm-line-item-quantity-container .sm-line-item-quantity ion-input {
    border: none;
    width: 5rem;
    text-align: center;
  }

  .sm-line-item-remove {
    font-size: 1.4rem;
  }

  .sm-line-item-delivery-time {
    position: relative;
  }

  .sm-line-item-delivery-time span:first-child {
    position: absolute;
    top: -20%
  }

  .sm-line-item-delivery-time span:last-child {
    margin-left: 0.5rem;
  }

  .sm-line-item-image {
    padding-right: 0.5rem;
  }

  .sm-read-more-btn {
    text-decoration: underline;
    margin-left: 0.5rem;
  }

  ion-grid {
    --ion-grid-padding: 1rem;
    --ion-grid-column-padding: 0;
  }
</style>
